import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

Vue.config.productionTip = false;

import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
// Init plugin
Vue.use(Loading);

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

Vue.use(VueSweetalert2, {
    confirmButtonColor: "#2e288c",
    cancelButtonColor: "#e30613",
});

import vuexI18n from "vuex-i18n";
Vue.use(vuexI18n.plugin, store);

const translationsEn = {
    Registratie: "Registration",

    Vorige: "Previous",
    Volgende: "Next",

    Familienaam: "Surname",
    Voornaam: "First name",
    "E-mail": "Mail",
    Telefoon: "Phone",
    Adres: "Address",

    Verplicht: "Required",
    Ongeldig: "Invalid",

    "Belgisch (al dan niet nog te behalen)":
        "I have a Belgian diploma or will have one in the near future.",
    "Anders (al dan niet nog te behalen)":
        "I have a foreign diploma or will have one in the near future.",

    "Heb je al een studie aan het hoger onderwijs in Vlaanderen achter de rug, hou er dan rekening mee dat je over voldoende leerkrediet moet beschikken om te kunnen starten aan het RITCS. Heb je hier vragen over? Contacteer dan":
        "If you (partly) completed a study at another university in Belgium please be sure to have enough credits to start your study at RITCS. In case of questions please contact",

    "Kies de toelatingsproef waaraan je wenst deel te nemen. Let hierbij goed op de specifieke data per toelatingsproef. Opgelet: het is niet mogelijk om twee maal deel te nemen aan dezelfde toelatingsproef.":
        "You can now choose your admission test. Pay close attention to the specific data connected to each test. Please note that apart from our Master Directing all programs are in Dutch and that it is not possible to participate twice for the same program.",
    Keuze: "Choice",

    "Je bent bijna geregistreerd voor de toelatingsproef.":
        "You are almost registered for the admission test.",
    "Na je bevestiging ontvang je van ons een mail met meer details over je toelatingsproef en de betaling van de administratieve vergoeding. Opgelet: je registratie is pas finaal na het betalen van deze vergoeding.":
        "After your confirmation you will receive an email with more details on your admission test and the payment of the administrative fee. Please note that your registration is complete after the payment of this fee.",
    "We staan met onze beide voeten in de toekomst, maar voorlopig laten we nog geen robots toe aan het RITCS School of Arts. Toon even je menselijke kant:":
        "We make art, not robots. Please confirm that you are not a robot.",

    "Je ontvangt binnen enkele minuten een e-mail. Controleer zeker ook je mappen met Spam en Ongewenste e-mail":
        "In a few minutes you’ll receive an e-mail. Please check also your spam and Junk Mail folder.",

    Bevestigen: "Confirm",
    "Registratie gelukt": "Registration done",

    "Lever hier je bestand(en) in voor je toelatingsproef. Opgelet: je kan maar één keer je bestand(en) uploaden. Bij meerdere bestanden dien je dus alle bestanden in één keer in te dienen.":
        "Via this way you can send us your file(s). Please note that you can only send us your file(s) once. If you have to send multiple files please send them in one session.",
    Kandidaat: "Candidate",
    "Aantal bestanden": "Amount of files",
    "Maximum totaalgrootte": "Maximum total file size",
    "Toegelaten bestandsextensies": "Allowed extensions",
    Bestanden: "Files",
    Bestand: "File",
    "Nieuwe upload": "New upload",
    "Onvoldoende bestanden": "Insufficient attachments",
    "Bevestig dat je geen robot bent:": "Confirm that you are not a robot:",
    Geen: "None",
    Versturen: "Send",
    Verstuurd: "Sent",
    "We hebben je bestanden goed ontvangen. Je krijgt binnen enkele minuten eveneens een bevestigingsmail.":
        "We have received your files. In a few minutes you will receive a confirmation mail.",
    Annuleren: "Cancel",
    "Ben je zeker?": "Are you sure?",
    "Je kan slechts een keer versturen": "You can only send once",

    "Bestand verwijderd.": "File deleted",
    "Bestand toegevoegd.": "File added",
    "Bevestig eerst dat je geen robot bent:":
        "Confirm that you are not a robot?",

    Verwijderen: "Delete",
    Verwijder: "Delete",
    "Indienen toelatingsproef": "Submit admission test",
    Gelukt: "Success",
    "Land diploma": "Country of diploma",
    "Naam diploma": "Diploma name",
    "Kies een land": "Choose a country",
    "Stuur een kopie van je diploma": "Please send a copy of your diploma to",
    "Doe je dit niet, dan loop je het risico dat je niet voldoet aan de toelatingsvoorwaarden.": "If you do not do this, you might run the risk of not meeting the admission requirements."
};

// translations can be kept in separate files for each language
// i.e. resources/i18n/de.json.
const translationsNl = {};

// add translations directly to the application
Vue.i18n.add("en", translationsEn);
Vue.i18n.add("nl", translationsNl);

// set the start locale to use
Vue.i18n.set("en");

Vue.filter("toLowerCase", function (value) {
    return value.toLowerCase();
});

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
