<template>
    <div class="submission container pb-2">
        <div
            v-if="!$store.state.settings"
            style="font-size: 1.3em"
            class="mt-2 jumbotron text-center"
        >
            Laden... Charging...
            <i class="fas fa-spinner fa-spin" style="font-size: 1em"></i>
            <p></p>
        </div>
        <div v-if="$store.state.settings" class="vld-parent">
            <div class="row mt-2">
                <div class="col-12 text-center" v-if="!currentLanguage">
                    <div v-if="!currentLanguage">
                        <div class="d-md-flex justify-content-evenly">
                            <div class="m-1 mt-4 shadow-sm p-2 bg-light">
                                <h1 class="mb-4">Indienen toelatingsproef</h1>
                                <button
                                    @click="setLanguage('dutch')"
                                    :class="{
                                        'btn-primary':
                                            currentLanguage == 'dutch',
                                        'btn-secondary':
                                            currentLanguage != 'dutch',
                                    }"
                                    class="btn btn-lg"
                                >
                                    Nederlands
                                </button>
                            </div>
                            <div class="m-1 mt-4 shadow-sm p-2 bg-light">
                                <h1 class="mb-4">Submit admission test</h1>
                                <button
                                    @click="setLanguage('english')"
                                    :class="{
                                        'btn-primary':
                                            currentLanguage == 'english',
                                        'btn-secondary':
                                            currentLanguage != 'english',
                                    }"
                                    class="btn btn-lg"
                                >
                                    English
                                </button>
                            </div>
                        </div>

                        <p class="text-center mt-4 text-danger">
                            Gebruik/Use Firefox, Chrome of/or Safari
                        </p>
                    </div>
                </div>

                <div v-if="submission && !submissionUploaded" class="col-12">
                    <h1>{{ "Indienen toelatingsproef" | translate }}</h1>
                    <h2>
                        {{ submission.toelatingsproef.opleiding }} -
                        {{ submission.toelatingsproef.name }}
                    </h2>
                    <p>
                        {{
                            "Lever hier je bestand(en) in voor je toelatingsproef. Opgelet: je kan maar één keer je bestand(en) uploaden. Bij meerdere bestanden dien je dus alle bestanden in één keer in te dienen."
                                | translate
                        }}
                    </p>

                    <dl>
                        <dt>{{ "Kandidaat" | translate }}</dt>
                        <dd>{{ submission.submitter.name }}</dd>
                        <dt>{{ "Aantal bestanden" | translate }}</dt>
                        <dd>
                            <div
                                v-if="
                                    submission.toelatingsproef.attachment_min ==
                                    submission.toelatingsproef.attachment_max
                                "
                            >
                                {{ submission.toelatingsproef.attachment_max }}
                            </div>
                            <div
                                v-if="
                                    submission.toelatingsproef.attachment_min !=
                                    submission.toelatingsproef.attachment_max
                                "
                            >
                                {{
                                    submission.toelatingsproef.attachment_min
                                }}-{{
                                    submission.toelatingsproef.attachment_max
                                }}
                            </div>
                        </dd>
                        <dt>{{ "Maximum totaalgrootte" | translate }}</dt>
                        <dd>
                            {{
                                prettyBytes(
                                    submission.toelatingsproef
                                        .attachment_total_filesize_mb *
                                        1000 *
                                        1000
                                )
                            }}
                        </dd>
                        <dt>
                            {{ "Toegelaten bestandsextensies" | translate }}
                        </dt>
                        <dd>pdf, wav, mp3, mp4</dd>
                        <dt>{{ "Bestanden" | translate }}</dt>
                        <dd></dd>
                        <dt></dt>
                        <dd>
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th>{{ "Bestand" | translate }}</th>
                                        <th style="text-align: right">
                                            <button
                                                class="btn btn-primary"
                                                id="uploadButton"
                                                :disabled="
                                                    !uploadButtonReady ||
                                                    submission.attachments
                                                        .length ==
                                                        submission
                                                            .toelatingsproef
                                                            .attachment_max ||
                                                    !uploadInstance ||
                                                    uploadInstance.isUploading()
                                                "
                                            >
                                                {{
                                                    "Nieuwe upload" | translate
                                                }}
                                            </button>
                                        </th>
                                    </tr>
                                    <tr
                                        v-if="
                                            uploadInstance &&
                                            uploadInstance.isUploading()
                                        "
                                    >
                                        <th colspan="10">
                                            <progress-bar
                                                size="huge"
                                                bar-color="#2e288c"
                                                :val="
                                                    uploadInstance.progress() *
                                                    100
                                                "
                                            />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-if="
                                            submission.attachments.length == 0
                                        "
                                    >
                                        <td colspan="10">
                                            {{ "Geen" | translate }}
                                        </td>
                                    </tr>
                                    <tr
                                        v-for="attachment in submission.attachments"
                                        :key="attachment.id"
                                    >
                                        <td>
                                            {{ attachment.human_filename }} -
                                            {{
                                                prettyBytes(attachment.filesize)
                                            }}
                                        </td>
                                        <td style="text-align: right">
                                            <button
                                                class="btn btn-danger"
                                                @click="
                                                    deleteAttachment(attachment)
                                                "
                                            >
                                                <i class="fas fa-trash-alt"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </dd>
                        <dt>
                            {{ "Versturen" | translate }}
                        </dt>
                        <dd>
                            <div
                                v-if="
                                    submission.attachments.length <
                                    submission.toelatingsproef.attachment_min
                                "
                                class="text-danger"
                            >
                                {{ "Onvoldoende bestanden" | translate }}
                            </div>
                            <div>
                                <p>
                                    {{
                                        "Bevestig eerst dat je geen robot bent:"
                                            | translate
                                    }}
                                </p>
                                <vue-recaptcha
                                    :sitekey="
                                        $store.state.settings
                                            .google_captcha_public_key
                                    "
                                    ref="recaptcha"
                                    @verify="onCaptchaVerify"
                                    class="mb-2"
                                ></vue-recaptcha>

                                <button
                                    class="btn btn-primary btn-lg"
                                    :disabled="
                                        submission.attachments.length <
                                            submission.toelatingsproef
                                                .attachment_min ||
                                        !recaptchaResponse
                                    "
                                    @click="submitConfirm()"
                                >
                                    <i class="fas fa-paper-plane"></i>
                                    {{ "Versturen" | translate }}
                                </button>
                            </div>
                        </dd>
                    </dl>
                </div>
                <div v-if="submissionUploaded" class="col-12">
                    <div
                        class="alert alert-primary d-flex align-items-center"
                        role="alert"
                    >
                        <i class="fas fa-check-circle fa-2x me-2"></i>
                        <div>
                            <h4 class="alert-heading">
                                {{ "Gelukt" | translate }}
                            </h4>
                            <p>
                                {{
                                    "We hebben je bestanden goed ontvangen. Je krijgt binnen enkele minuten eveneens een bevestigingsmail."
                                        | translate
                                }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import helper from "../helper.js";
import daoSettings from "../dao/setting";
import daoSubmission from "../dao/submission";

import axios from "axios";
import _ from "lodash";
import VueRecaptcha from "vue-recaptcha";

import prettyBytes from "pretty-bytes-es5";

import Resumable from "resumablejs";

import ProgressBar from "vue-simple-progress";

export default {
    name: "submission",
    components: {
        VueRecaptcha,
        ProgressBar,
    },
    data() {
        return {
            currentLanguage: null,
            toelatingsproefList: null,
            recaptchaResponse: null,
            skipRecaptcha: false,
            submission: null,
            uploadButtonReady: false,
            uploadInstance: null,
            submissionUploaded: false,
        };
    },
    computed: {
        opleidingList: function () {
            var list = [];
            this.toelatingsproefList.forEach(function (toelatingsproef) {
                if (list.indexOf(toelatingsproef.opleiding) !== -1) {
                    return;
                }
                list.push(toelatingsproef.opleiding);
            });
            return list;
        },
        toelatingsproefSelectedList: function () {
            return this.toelatingsproefList.filter(function (toelatingsproef) {
                return toelatingsproef.selected;
            });
        },
    },
    methods: {
        setupUpload: function () {
            var r = new Resumable({
                simultaneousUploads: 1,
                target: this.getUploadEndpoint(),
                fileType: ["pdf", "wav", "mp3", "mp4"],
                chunkSize: 2 * 1024 * 1024,
                maxFileSize:
                    this.submission.toelatingsproef
                        .attachment_total_filesize_mb *
                    1024 *
                    1024,
                query: {},
            });

            if (!r.support) {
                this.$swal.fire(
                    "Fout",
                    "Uploaden wordt in je browser niet ondersteund. Gebruik een recente browser.",
                    "error"
                );
            }

            r.assignBrowse(document.getElementById("uploadButton"));

            this.uploadButtonReady = true;

            r.on("fileAdded", function (file) {
                this.upload();
            });
            r.on("fileSuccess", (file, message) => {
                this.$swal.fire(
                    "Succes",
                    this.$t("Bestand toegevoegd."),
                    "success"
                );
                this.loadSubmission(this.submission.id);
                r.removeFile(file);
            });
            r.on("fileError", (file, error) => {
                console.log("error", error);
                try {
                    error = JSON.parse(error).message;
                } catch (e) {
                    // Use original error
                }
                this.$swal.fire("Fout", helper.handleError(error), "error");
            });

            console.log("Setup upload done");

            this.uploadInstance = r;
        },
        loadSubmission: function (id) {
            daoSubmission
                .get(this.$route.params.submission_token)
                .then((submission) => {
                    this.submission = submission;
                })
                .catch((error) => {
                    console.error("getSubmission", error);
                    this.$swal.fire("Fout", helper.handleError(error), "error");
                });
        },
        deleteAttachment: function (attachment) {
            this.$swal
                .fire({
                    title: this.$t("Ben je zeker?"),
                    text:
                        this.$t("Verwijder") + " " + attachment.human_filename,
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonText: this.$t("Verwijder"),
                    cancelButtonText: this.$t("Annuleren"),
                })
                .then((result) => {
                    console.log("result", result);
                    if (!result.value) {
                        return;
                    }
                    let loader = this.$loading.show();
                    daoSubmission
                        .deleteAttachment(
                            this.submission.submission_token,
                            attachment.id
                        )
                        .then(() => {
                            loader.hide();
                            this.$swal.fire(
                                "Succes",
                                this.$t("Bestand verwijderd."),
                                "success"
                            );

                            this.loadSubmission(this.submission.id);
                        })
                        .catch((error) => {
                            loader.hide();
                            console.error("deleteAttachment", error);
                            this.$swal.fire(
                                "Fout",
                                helper.handleError(error),
                                "error"
                            );
                        });
                });
        },
        onUploadError: function (error) {
            console.log("onUploadError", error);
            this.$swal.fire("Fout", helper.handleError(error), "error");
        },
        getUploadEndpoint: function () {
            return (
                "/api/public_submission/upload/" +
                this.submission.submission_token
            );
        },
        prettyBytes: function (value) {
            return prettyBytes(value);
        },
        submitConfirm: function () {
            this.$swal
                .fire({
                    title: this.$t("Ben je zeker?"),
                    text: this.$t("Je kan slechts een keer versturen"),
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonText: this.$t("Versturen"),
                    cancelButtonText: this.$t("Annuleren"),
                })
                .then((result) => {
                    console.log("result", result);
                    if (!result.value) {
                        return;
                    }

                    this.submit();
                });
        },
        submit: function () {
            let loader = this.$loading.show();
            axios
                .post("/api/public_submission/submit_upload", {
                    submission_token: this.submission.submission_token,
                    recaptcha_response: this.recaptchaResponse,
                })
                .then(() => {
                    loader.hide();
                    this.$refs.recaptcha.reset();
                    this.recaptchaResponse = null;

                    this.submissionUploaded = true;
                })
                .catch((error) => {
                    loader.hide();
                    if (!this.skipRecaptcha) {
                        this.$refs.recaptcha.reset();
                        this.recaptchaResponse = null;
                    }

                    console.error("uploadAttachment", error);
                    this.$swal.fire("Fout", helper.handleError(error), "error");
                });
        },
        onCaptchaVerify: function (response) {
            this.recaptchaResponse = response;
            console.log("onCaptchaVerify: " + response);
        },
        getForOpleiding: function (opleiding) {
            return this.toelatingsproefList.filter(function (toelatingsproef) {
                return toelatingsproef.opleiding == opleiding;
            });
        },
        setLanguage: function (language) {
            let loader = this.$loading.show();
            daoSubmission
                .get(this.$route.params.submission_token)
                .then((submission) => {
                    loader.hide();

                    this.submission = submission;

                    this.currentLanguage = language;

                    if (this.currentLanguage == "english") {
                        this.$i18n.set("en");
                    } else {
                        this.$i18n.set("nl");
                    }

                    setTimeout(
                        function () {
                            this.setupUpload();
                        }.bind(this),
                        1000
                    );
                })
                .catch((error) => {
                    loader.hide();
                    console.error("getSubmission", error);
                    this.$swal.fire("Fout", helper.handleError(error), "error");
                });
        },
    },
    mounted() {
        let loader = this.$loading.show();
        daoSettings
            .getPublic()
            .then((settings) => {
                loader.hide();
                this.$store.commit("setSettings", settings);
            })
            .catch((error) => {
                loader.hide();
                console.error("getSettings", error);
                this.$swal.fire("Fout", helper.handleError(error), "error");
            });
    },
};
</script>
