import Q from "q";
import axios from "axios";
import helper from "../helper.js";

export default {
    get: async function(submissionKey) {
        return await axios
            .get(
                    "/api/public_submission/get_by_key/" +
                    submissionKey
            )
            .then(response => response.data);
    },
    deleteAttachment: async function(submissionKey, attachmentId) {
        return await axios
            .get(
                    "/api/public_submission/delete_attachment/" +
                    submissionKey +
                    "/" +
                    attachmentId
            )
            .then(response => response.data);
    }
};
