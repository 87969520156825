<template>
    <div class="submission container pb-2 vld-parent">
        <div
            style="text-align: center"
            v-if="currentStep.name != 'language_selection'"
        >
            <h1>
                {{
                    currentLanguage == "dutch"
                        ? "Toelatingsproef"
                        : "Admission Test"
                }}
            </h1>
        </div>

        <div
            v-if="!$store.state.settings || toelatingsproefList == null"
            style="font-size: 1.3em"
            class="mt-2 jumbotron text-center"
        >
            Laden... Charging...
            <i class="fas fa-spinner fa-spin" style="font-size: 1em"></i>
            <p></p>
        </div>

        <div
            v-if="
                $store.state.settings &&
                toelatingsproefList &&
                toelatingsproefList.length == 0
            "
            class="text-center"
        >
            <div class="d-md-flex justify-content-evenly">
                <div
                    class="mt-4 shadow-lg p-3 mb-5 p-3 bg-danger text-white rounded"
                >
                    <p>Er zijn geen toelatingsproeven beschikbaar.</p>
                    <p>No admission tests are available.</p>
                </div>
            </div>
        </div>
        <div
            v-if="
                $store.state.settings &&
                currentStep &&
                toelatingsproefList &&
                toelatingsproefList.length
            "
        >
            <div class="row mt-2">
                <div class="col-12">
                    <p
                        class="h3"
                        style="text-align: center"
                        v-if="currentStep.title"
                    >
                        {{ currentStep.title | translate }}
                    </p>

                    <div
                        v-if="currentStep.name == 'language_selection'"
                        style="text-align: center"
                    >
                        <div class="d-md-flex justify-content-evenly">
                            <div class="m-1 mt-4 shadow-sm p-2 bg-light">
                                <h1 class="mb-4">Toelatingsproef</h1>
                                <button
                                    @click="setLanguage('dutch')"
                                    :class="{
                                        'btn-primary':
                                            currentLanguage == 'dutch',
                                        'btn-secondary':
                                            currentLanguage != 'dutch',
                                    }"
                                    class="btn btn-lg"
                                >
                                    Nederlands
                                </button>
                            </div>
                            <div class="m-1 mt-4 shadow-sm p-2 bg-light">
                                <h1 class="mb-4">Admission Test</h1>
                                <button
                                    @click="setLanguage('english')"
                                    :class="{
                                        'btn-primary':
                                            currentLanguage == 'english',
                                        'btn-secondary':
                                            currentLanguage != 'english',
                                    }"
                                    class="btn btn-lg"
                                >
                                    English
                                </button>
                            </div>
                        </div>

                        <p class="text-center mt-4 text-danger">
                            Gebruik/Use Firefox, Chrome of/or Safari
                        </p>
                    </div>
                </div>
            </div>

            <div
                v-if="currentStep.name == 'registration'"
                class="row bg-light mb-2 rounded-2"
            >
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label for="firstname">{{
                            "Voornaam" | translate
                        }}</label>
                        <span v-if="validatePersonForm.firstname.length">
                            <span
                                v-for="(
                                    error, $index
                                ) in validatePersonForm.firstname"
                                :key="$index"
                                class="badge bg-danger"
                                >{{ error }}</span
                            >
                        </span>
                        <input
                            type="firstname"
                            class="form-control"
                            id="firstname"
                            v-model="user.firstname"
                        />
                    </div>
                </div>

                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label for="surename">{{
                            "Familienaam" | translate
                        }}</label>
                        <span v-if="validatePersonForm.surename.length">
                            <span
                                v-for="(
                                    error, $index
                                ) in validatePersonForm.surename"
                                :key="$index"
                                class="badge bg-danger"
                                >{{ error }}</span
                            >
                        </span>
                        <input
                            type="surename"
                            class="form-control"
                            id="surename"
                            v-model="user.surename"
                        />
                    </div>
                </div>

                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label for="email">{{ "E-mail" | translate }}</label>
                        <span v-if="validatePersonForm.email.length">
                            <span
                                v-for="(
                                    error, $index
                                ) in validatePersonForm.email"
                                :key="$index"
                                class="badge bg-danger"
                                >{{ error }}</span
                            >
                        </span>
                        <input
                            type="email"
                            class="form-control"
                            id="email"
                            required
                            v-model="user.email"
                        />
                    </div>
                </div>

                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label for="phone">{{ "Telefoon" | translate }}</label>
                        <span v-if="validatePersonForm.phone.length">
                            <span
                                v-for="(
                                    error, $index
                                ) in validatePersonForm.phone"
                                :key="$index"
                                class="badge bg-danger"
                                >{{ error }}</span
                            >
                        </span>
                        <input
                            type="text"
                            class="form-control"
                            id="phone"
                            required
                            v-model="user.phone"
                        />
                    </div>
                </div>

                <div class="col-12">
                    <div class="form-group">
                        <label for="address">{{ "Adres" | translate }}</label>
                        <span v-if="validatePersonForm.address.length">
                            <span
                                v-for="(
                                    error, $index
                                ) in validatePersonForm.address"
                                :key="$index"
                                class="badge bg-danger"
                                >{{ error }}</span
                            >
                        </span>
                        <textarea
                            class="form-control"
                            id="address"
                            required
                            v-model="user.address"
                        ></textarea>
                    </div>
                </div>

                <div class="col-12">
                    <label>{{ "Diploma" | translate }}</label>
                    <span v-if="validatePersonForm.diploma.length">
                        <span
                            v-for="(
                                error, $index
                            ) in validatePersonForm.diploma"
                            :key="$index"
                            class="badge bg-danger"
                            >{{ error }}</span
                        >
                    </span>
                    <div
                        v-for="diplomaOption in $store.state.settings.diploma_list.filter(
                            (d) => d.value != null
                        )"
                        :key="diplomaOption.value"
                    >
                        <input
                            type="radio"
                            :id="'diploma_' + diplomaOption.value"
                            v-model="diploma.value"
                            :value="diplomaOption.value"
                        />
                        <label
                            :for="'diploma_' + diplomaOption.value"
                            class="ps-2"
                            :class="{
                                'text-danger': diplomaOption.value == null,
                            }"
                            >{{ diplomaOption.label | translate }}</label
                        >
                    </div>
                </div>

                <div class="col-12 mt-2" v-if="diploma.value === 'foreign'">
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <label for="diploma_country">{{
                                "Land diploma" | translate
                            }}</label>
                            <span
                                v-if="validatePersonForm.diploma_country.length"
                            >
                                <span
                                    v-for="(
                                        error, $index
                                    ) in validatePersonForm.diploma_country"
                                    :key="$index"
                                    class="badge bg-danger"
                                    >{{ error }}</span
                                >
                            </span>
                            <select
                                class="form-control"
                                id="diploma_country"
                                v-model="diploma.country"
                                required
                            >
                                <option :value="null">
                                    {{ "Kies een land" | translate }}
                                </option>
                                <option
                                    v-for="countryOption in $store.state.settings.countries[
                                        currentLanguage == 'english'
                                            ? 'en'
                                            : 'nl'
                                    ].filter((l) => l.alpha3 != 'bel')"
                                    :key="countryOption.alpha3"
                                    :value="countryOption.alpha3"
                                >
                                    {{ countryOption.name }}
                                </option>
                            </select>
                        </div>
                        <div class="col-12 col-md-6">
                            <label for="diploma_country">{{
                                "Naam diploma" | translate
                            }}</label>
                            <span v-if="validatePersonForm.diploma_name.length">
                                <span
                                    v-for="(
                                        error, $index
                                    ) in validatePersonForm.diploma_name"
                                    :key="$index"
                                    class="badge bg-danger"
                                    >{{ error }}</span
                                >
                            </span>
                            <input
                                type="text"
                                class="form-control"
                                v-model="diploma.name"
                                required
                                :min="3"
                            />
                        </div>
                    </div>
                </div>

                <div class="col-12 mt-2" v-if="diploma.value === 'native'">
                    <p>
                        {{
                            "Heb je al een studie aan het hoger onderwijs in Vlaanderen achter de rug, hou er dan rekening mee dat je over voldoende leerkrediet moet beschikken om te kunnen starten aan het RITCS. Heb je hier vragen over? Contacteer dan"
                                | translate
                        }}
                        <a
                            :href="
                                'mailto:' +
                                $store.state.settings.contact_leerkrediet
                            "
                            >{{ $store.state.settings.contact_leerkrediet }}</a
                        >.
                    </p>
                </div>
                <div class="col-12 mt-2" v-if="diploma.value === 'foreign'">
                    <p>
                        {{ "Stuur een kopie van je diploma" | translate }}
                        <a
                            :href="
                                'mailto:' +
                                $store.state.settings.contact_diploma_foreign
                            "
                            >{{
                                $store.state.settings.contact_diploma_foreign
                            }}</a
                        >.
                        {{
                            "Doe je dit niet, dan loop je het risico dat je niet voldoet aan de toelatingsvoorwaarden."
                                | translate
                        }}
                    </p>
                </div>
            </div>

            <div v-if="currentStep.name == 'selection'" class="row" style="">
                <div class="col-12">
                    <p>
                        {{
                            "Kies de toelatingsproef waaraan je wenst deel te nemen. Let hierbij goed op de specifieke data per toelatingsproef. Opgelet: het is niet mogelijk om twee maal deel te nemen aan dezelfde toelatingsproef."
                                | translate
                        }}
                    </p>
                </div>

                <div
                    class="col-12"
                    v-for="opleiding in opleidingList"
                    :key="opleiding"
                >
                    <h3>{{ opleiding }}</h3>

                    <div
                        class="alert alert-danger"
                        role="alert"
                        v-if="
                            toelatingsproefSelectedList.length >
                            $store.state.settings.submission_max
                        "
                    >
                        Maximum deel te nemen toelatingsproeven:
                        {{ $store.state.settings.submission_max }}
                    </div>
                    <ul>
                        <li
                            v-for="toelatingsproef in getForOpleiding(
                                opleiding
                            )"
                            :key="toelatingsproef.id"
                            title="Kiezen"
                            class="form-check"
                        >
                            <div
                                :class="{
                                    active: toelatingsproef.selected,
                                    hover: canSelectToelatingsproef(
                                        toelatingsproef
                                    ),
                                }"
                            >
                                <input
                                    class="form-check-input"
                                    type="checkbox"
                                    :id="
                                        'toelatingsproef_' + toelatingsproef.id
                                    "
                                    :checked="toelatingsproef.selected"
                                    :disabled="
                                        !canSelectToelatingsproef(
                                            toelatingsproef
                                        )
                                    "
                                    @click="
                                        toggleToelatingsproef(toelatingsproef)
                                    "
                                />

                                <label
                                    class="form-check-label"
                                    :for="
                                        'toelatingsproef_' + toelatingsproef.id
                                    "
                                >
                                    {{ toelatingsproef.name }},
                                    {{ toelatingsproef.programmatie }}, deadline
                                    {{ toelatingsproef.deadline }}
                                </label>

                                <span
                                    v-if="
                                        toelatingsproef.selected &&
                                        toelatingsproef.options.length
                                    "
                                >
                                    <span
                                        v-if="
                                            getSelected(toelatingsproef.options)
                                                .length <
                                                toelatingsproef.options_min ||
                                            getSelected(toelatingsproef.options)
                                                .length >
                                                toelatingsproef.options_max
                                        "
                                        class="badge bg-danger"
                                    >
                                        <span
                                            v-if="
                                                toelatingsproef.options_min ==
                                                toelatingsproef.options_max
                                            "
                                        >
                                            {{ toelatingsproef.options_min }}
                                            {{
                                                "Verplicht"
                                                    | translate
                                                    | toLowerCase
                                            }}
                                        </span>
                                        <span v-else>
                                            Min.
                                            {{ toelatingsproef.options_min }},
                                            max.
                                            {{ toelatingsproef.options_max }}
                                        </span>
                                    </span>
                                    <span v-else>
                                        <span
                                            v-if="
                                                toelatingsproef.options_min ==
                                                toelatingsproef.options_max
                                            "
                                            class="badge bg-secondary"
                                        >
                                            {{ toelatingsproef.options_min }}
                                            {{
                                                "Verplicht"
                                                    | translate
                                                    | toLowerCase
                                            }}
                                        </span>
                                        <span v-else class="badge bg-secondary">
                                            Min.
                                            {{ toelatingsproef.options_min }},
                                            max.
                                            {{
                                                toelatingsproef.options_max
                                            }}</span
                                        >
                                    </span>
                                </span>
                            </div>

                            <div
                                v-if="
                                    toelatingsproef.selected &&
                                    toelatingsproef.options.length
                                "
                            >
                                <ul class="ps-4">
                                    <li
                                        v-for="toelatingsproefOption in toelatingsproef.options"
                                        :key="toelatingsproefOption.id"
                                        :class="{
                                            active: toelatingsproefOption.selected,
                                            hover: canSelectToelatingsproef(
                                                toelatingsproef
                                            ),
                                        }"
                                    >
                                        <input
                                            class="form-check-input"
                                            type="checkbox"
                                            :id="
                                                'toelatingsproef_option_' +
                                                toelatingsproefOption.id
                                            "
                                            :value="
                                                toelatingsproefOption.selected
                                            "
                                            :disabled="
                                                !canSelectToelatingsproef(
                                                    toelatingsproef
                                                )
                                            "
                                            @click="
                                                toggleToelatingsproefOption(
                                                    toelatingsproefOption
                                                )
                                            "
                                        />

                                        <label
                                            class="form-check-label"
                                            :for="
                                                'toelatingsproef_option_' +
                                                toelatingsproefOption.id
                                            "
                                        >
                                            {{ toelatingsproefOption.label }}
                                        </label>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

            <div v-if="currentStep.name == 'confirm'" class="row confirm">
                <div class="col-12">
                    <p>
                        {{
                            "Je bent bijna geregistreerd voor de toelatingsproef."
                                | translate
                        }}
                    </p>

                    <p>
                        {{
                            "Na je bevestiging ontvang je van ons een mail met meer details over je toelatingsproef en de betaling van de administratieve vergoeding. Opgelet: je registratie is pas finaal na het betalen van deze vergoeding."
                                | translate
                        }}
                    </p>

                    <p>
                        {{
                            "We staan met onze beide voeten in de toekomst, maar voorlopig laten we nog geen robots toe aan het RITCS School of Arts. Toon even je menselijke kant:"
                                | translate
                        }}
                    </p>

                    <vue-recaptcha
                        :sitekey="
                            $store.state.settings.google_captcha_public_key
                        "
                        ref="recaptcha"
                        @verify="onCaptchaVerify"
                        class="mb-2"
                    ></vue-recaptcha>
                </div>
            </div>

            <div
                v-if="currentStep.name == 'confirmation'"
                class="row confirmation"
            >
                <div class="col-12">
                    <div
                        class="alert alert-primary d-flex align-items-center"
                        role="alert"
                    >
                        <i class="fas fa-check-circle fa-2x me-2"></i>
                        <div>
                            <h4 class="alert-heading">
                                {{ "Registratie gelukt" | translate }}
                            </h4>
                            <p>
                                {{
                                    "Je ontvangt binnen enkele minuten een e-mail. Controleer zeker ook je mappen met Spam en Ongewenste e-mail."
                                        | translate
                                }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row" style="margin-bottom: 1em">
                <div class="col-6">
                    <button
                        v-if="canPrevStep(currentStep.name)"
                        class="wizard-nav-button btn btn-primary btn-lg"
                        @click="goPrevStep(currentStep)"
                    >
                        <i class="fa fa-arrow-left"></i>
                        {{ "Vorige" | translate }}
                    </button>
                </div>
                <div class="col-6">
                    <button
                        v-if="canNextStep(currentStep.name)"
                        class="wizard-nav-button btn btn-primary btn-lg"
                        style="float: right"
                        :disabled="nextDisabled()"
                        @click="goNextStep(currentStep)"
                    >
                        <span v-if="currentStep.name == 'confirm'">
                            {{ "Bevestigen" | translate }}
                            <i class="fa fa-check"></i>
                        </span>
                        <span v-if="currentStep.name != 'confirm'">
                            {{ "Volgende" | translate }}
                            <i class="fa fa-arrow-right"></i>
                        </span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import helper from "../helper.js";
import daoSettings from "../dao/setting";

import axios from "axios";
import moment from "moment";
import _ from "lodash";
import VueRecaptcha from "vue-recaptcha";

export default {
    name: "submission",
    components: { VueRecaptcha },
    data() {
        return {
            toelatingsproefList: null,
            currentLanguage: "unknown",
            settings: {
                languages: {
                    nl: {
                        label: "Nederlands",
                    },
                    en: {
                        label: "English",
                    },
                },
            },
            recaptchaResponse: null,
            skipRecaptcha: false,
            user: {
                surename: "",
                firstname: "",
                email: "",
                phone: "",
                address: "",
            },
            diploma: {
                value: null,
                country: null,
                name: "",
            },
            currentStep: null,
            steps: [
                {
                    title: "",
                    name: "language_selection",
                    description: "",
                },
                {
                    title: "Registratie",
                    name: "registration",
                },
                {
                    title: "Keuze",
                    name: "selection",
                },
                {
                    title: "Bevestigen",
                    name: "confirm",
                },
                {
                    title: "",
                    name: "confirmation",
                },
            ],
        };
    },
    computed: {
        validatePersonForm: function () {
            var validation = {
                firstname: [],
                surename: [],
                email: [],
                phone: [],
                address: [],
                diploma: [],
                diploma_country: [],
                diploma_name: [],
                errors: 0,
            };

            var firstname = this.user.firstname.trim();
            var surename = this.user.surename.trim();
            var email = this.user.email.trim();
            var phone = this.user.phone.trim();
            var address = this.user.address.trim();
            var diplomaName = this.diploma.name.trim();

            if (!firstname) {
                validation.firstname.push(this.$t("Verplicht"));
                validation.errors++;
            }
            if (!surename) {
                validation.surename.push(this.$t("Verplicht"));
                validation.errors++;
            }
            if (!helper.isValidEmail(email)) {
                validation.email.push(this.$t("Ongeldig"));
                validation.errors++;
            }
            if (phone.length < 8) {
                validation.phone.push(this.$t("Ongeldig"));
                validation.errors++;
            }
            if (address.length < 10) {
                validation.address.push(this.$t("Ongeldig"));
                validation.errors++;
            }
            if (this.diploma == null) {
                validation.diploma.push(this.$t("Verplicht"));
                validation.errors++;
            } else if (this.diploma.value === "foreign") {
                if (!this.diploma.country) {
                    validation.diploma_country.push(this.$t("Verplicht"));
                    validation.errors++;
                }

                if (diplomaName.length < 3) {
                    validation.diploma_name.push(this.$t("Ongeldig"));
                    validation.errors++;
                }
            }

            return validation;
        },
        opleidingList: function () {
            var list = [];
            this.toelatingsproefList.forEach(function (toelatingsproef) {
                if (list.indexOf(toelatingsproef.opleiding) !== -1) {
                    return;
                }
                list.push(toelatingsproef.opleiding);
            });
            return list;
        },
        toelatingsproefSelectedList: function () {
            return this.toelatingsproefList.filter(function (toelatingsproef) {
                return (
                    toelatingsproef.selected ||
                    toelatingsproef.options.find((o) => o.selected)
                );
            });
        },
    },
    methods: {
        isToelatingsproefValid: function (toelatingsproef) {
            if (!toelatingsproef.selected) {
                return false;
            }
            if (toelatingsproef.options.length == 0) {
                return true;
            }

            var selectedOptionList = this.getSelected(toelatingsproef.options);

            return (
                selectedOptionList.length >= toelatingsproef.options_min &&
                selectedOptionList.length <= toelatingsproef.options_max
            );
        },
        getSelected: function (list) {
            return list.filter((e) => e.selected);
        },
        toggleToelatingsproefOption: function (toelatingsproefOption) {
            var toelatingsproef = this.toelatingsproefList.find(
                (t) => t.id == toelatingsproefOption.toelatingsproef_id
            );

            if (!this.canSelectToelatingsproef(toelatingsproef)) {
                return false;
            }

            toelatingsproefOption.selected = !toelatingsproefOption.selected;
        },
        toggleToelatingsproef: function (toelatingsproef) {
            if (!this.canSelectToelatingsproef(toelatingsproef)) {
                return false;
            }
            toelatingsproef.selected = !toelatingsproef.selected;

            toelatingsproef.options.forEach((option) => {
                option.selected = false;
            });
        },
        canSelectToelatingsproef(toelatingsproef) {
            if (
                toelatingsproef.selected ||
                toelatingsproef.options.find((t) => t.selected)
            ) {
                return true;
            }

            return (
                this.toelatingsproefList.find((toelatingsproefSelected) => {
                    return (
                        toelatingsproefSelected.opleiding ==
                            toelatingsproef.opleiding &&
                        (toelatingsproefSelected.selected ||
                            toelatingsproefSelected.options.find(
                                (o) => o.selected
                            ))
                    );
                }) == null
            );
        },
        submit: function () {
            let loader = this.$loading.show();

            axios
                .post("/api/public_submission/submit", {
                    user: this.user,
                    toelatingsproefs: this.toelatingsproefSelectedList,
                    diploma: this.diploma,
                    recaptcha_response: this.recaptchaResponse,
                })
                .then(() => {
                    this.$refs.recaptcha.reset();
                    this.recaptchaResponse = null;

                    this.currentStep = this.steps[this.steps.length - 1];
                })
                .catch((error) => {
                    if (!this.skipRecaptcha) {
                        this.$refs.recaptcha.reset();
                        this.recaptchaResponse = null;
                    }

                    console.error("uploadAttachment", error);
                    this.$swal.fire("Fout", helper.handleError(error), "error");
                })
                .finally(() => {
                    loader.hide();
                });
        },
        onCaptchaVerify: function (response) {
            this.recaptchaResponse = response;
            console.log("onCaptchaVerify: " + response);
        },
        getForOpleiding: function (opleiding) {
            return this.toelatingsproefList.filter(function (toelatingsproef) {
                return toelatingsproef.opleiding == opleiding;
            });
        },
        nextDisabled: function () {
            if (!this.currentStep.name == "registration") {
                if (this.currentStep.name == "confirm") {
                    return this.recaptchaResponse != null && !skipRecaptcha;
                }
                return false;
            }
            return this.validatePersonForm.errors > 0;
        },
        setLanguage: function (language) {
            this.currentLanguage = language;

            if (this.currentLanguage == "english") {
                this.$i18n.set("en");
            } else {
                this.$i18n.set("nl");
            }

            this.goNextStep(this.currentStep);
        },
        canPrevStep: function (step) {
            switch (step) {
                case "language_selection":
                    return false;
                case "confirmation":
                    return false;
            }
            return true;
        },
        canNextStep: function (step) {
            switch (step) {
                case "language_selection":
                    if (this.currentLanguage !== "unknown") {
                        return true;
                    }
                    break;
                case "registration":
                    return true;
                case "selection":
                    console.log(
                        "this.toelatingsproefSelectedList.length",
                        this.toelatingsproefSelectedList.length
                    );

                    if (
                        this.toelatingsproefSelectedList.length == 0 ||
                        this.toelatingsproefSelectedList.length > 5
                    ) {
                        return false;
                    }

                    if (
                        this.toelatingsproefSelectedList.find(
                            (t) => !this.isToelatingsproefValid(t)
                        )
                    ) {
                        return false;
                    }

                    return true;

                case "confirm":
                    return this.recaptchaResponse != null || this.skipRecaptcha;
            }
            return false;
        },
        goNextStep: function (currentStep) {
            if (this.currentStep.name == "confirm") {
                this.submit();
            } else {
                this.recaptchaResponse = null;
                this.currentStep =
                    this.steps[this.steps.indexOf(currentStep) + 1];
            }
        },
        goPrevStep: function (currentStep) {
            this.currentStep = this.steps[this.steps.indexOf(currentStep) - 1];
        },
    },
    beforeMount: function () {
        this.currentStep = this.steps[0];
    },
    async mounted() {
        let loader = this.$loading.show();

        daoSettings
            .getPublic()
            .then(async (settings) => {
                await this.$store.commit("setSettings", settings);

                this.toelatingsproefList = settings.toelatingsproefs.map(
                    (toelatingsproef) => {
                        toelatingsproef.deadline = moment(
                            toelatingsproef.deadline,
                            "YYYY-MM-DD"
                        ).format("D/M/Y");
                        toelatingsproef.selected = false;

                        toelatingsproef.options = toelatingsproef.options.map(
                            (option) => {
                                option.selected = false;
                                return _.clone(option);
                            }
                        );
                        return _.clone(toelatingsproef);
                    }
                );
            })
            .catch((error) => {
                console.error("getSettings", error);
                this.$swal.fire("Fout", helper.handleError(error), "error");
            })
            .finally(() => {
                loader.hide();
            });
    },
};
</script>
