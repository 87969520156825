import Vue from 'vue'
import VueRouter from 'vue-router'

import Submission from '@/components/Submission'
import Upload from '@/components/Upload'
import NotFound from '@/components/NotFound'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Submission',
    component: Submission
  },
  {
    path: '/upload/:submission_token',
    name: 'Upload',
    component: Upload
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFound
  },
]

const router = new VueRouter({
  mode: 'history',
  base: 'ui',
  routes
})

export default router
