<template>
    <div class="submission container pb-2">
        <div
            v-if="!$store.state.settings"
            style="font-size: 1.3em"
            class="mt-2 jumbotron text-center"
        >
            Laden... Charging...
            <i class="fas fa-spinner fa-spin" style="font-size: 1em;"></i>
            <p></p>
        </div>
        <div v-if="$store.state.settings">
            <div class="jumbotron mt-2" style="font-size: 1.3em">
                <p>
                    De pagina die je zocht bestaat niet. Klik
                    <router-link :to="'/'" class="" tag="a">hier</router-link>
                    om je voor een toelatingsproef in te schrijven
                </p>
                <p>
                    The page you requested doesn't exist. Click
                    <router-link :to="'/'" class="" tag="a">here</router-link>
                    to apply for XXX
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import helper from "../helper.js";
import daoSettings from "../dao/setting";
import _ from "lodash";
// import VueRecaptcha from 'vue-recaptcha';

export default {
    name: "submission",
    // components: { VueRecaptcha },
    data() {
        return {
            currentLanguage: null,
            toelatingsproefList: null,
            recaptchaResponse: null,
            skipRecaptcha: true
        };
    },
    computed: {},
    methods: {},
    mounted() {
        daoSettings
            .getPublic()
            .then(
                function(settings) {
                    this.$store.commit("setSettings", settings);
                }.bind(this)
            )
            .catch(
                function(error) {
                    console.error("getSettings", error);
                    this.$swal("Fout", helper.handleError(error), "error");
                }.bind(this)
            );
    }
};
</script>
