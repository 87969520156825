var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"submission container pb-2"},[(!_vm.$store.state.settings)?_c('div',{staticClass:"mt-2 jumbotron text-center",staticStyle:{"font-size":"1.3em"}},[_vm._v(" Laden... Charging... "),_c('i',{staticClass:"fas fa-spinner fa-spin",staticStyle:{"font-size":"1em"}}),_c('p')]):_vm._e(),(_vm.$store.state.settings)?_c('div',{staticClass:"vld-parent"},[_c('div',{staticClass:"row mt-2"},[(!_vm.currentLanguage)?_c('div',{staticClass:"col-12 text-center"},[(!_vm.currentLanguage)?_c('div',[_c('div',{staticClass:"d-md-flex justify-content-evenly"},[_c('div',{staticClass:"m-1 mt-4 shadow-sm p-2 bg-light"},[_c('h1',{staticClass:"mb-4"},[_vm._v("Indienen toelatingsproef")]),_c('button',{staticClass:"btn btn-lg",class:{
                                    'btn-primary':
                                        _vm.currentLanguage == 'dutch',
                                    'btn-secondary':
                                        _vm.currentLanguage != 'dutch',
                                },on:{"click":function($event){return _vm.setLanguage('dutch')}}},[_vm._v(" Nederlands ")])]),_c('div',{staticClass:"m-1 mt-4 shadow-sm p-2 bg-light"},[_c('h1',{staticClass:"mb-4"},[_vm._v("Submit admission test")]),_c('button',{staticClass:"btn btn-lg",class:{
                                    'btn-primary':
                                        _vm.currentLanguage == 'english',
                                    'btn-secondary':
                                        _vm.currentLanguage != 'english',
                                },on:{"click":function($event){return _vm.setLanguage('english')}}},[_vm._v(" English ")])])]),_c('p',{staticClass:"text-center mt-4 text-danger"},[_vm._v(" Gebruik/Use Firefox, Chrome of/or Safari ")])]):_vm._e()]):_vm._e(),(_vm.submission && !_vm.submissionUploaded)?_c('div',{staticClass:"col-12"},[_c('h1',[_vm._v(_vm._s(_vm._f("translate")("Indienen toelatingsproef")))]),_c('h2',[_vm._v(" "+_vm._s(_vm.submission.toelatingsproef.opleiding)+" - "+_vm._s(_vm.submission.toelatingsproef.name)+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm._f("translate")("Lever hier je bestand(en) in voor je toelatingsproef. Opgelet: je kan maar één keer je bestand(en) uploaden. Bij meerdere bestanden dien je dus alle bestanden in één keer in te dienen."))+" ")]),_c('dl',[_c('dt',[_vm._v(_vm._s(_vm._f("translate")("Kandidaat")))]),_c('dd',[_vm._v(_vm._s(_vm.submission.submitter.name))]),_c('dt',[_vm._v(_vm._s(_vm._f("translate")("Aantal bestanden")))]),_c('dd',[(
                                _vm.submission.toelatingsproef.attachment_min ==
                                _vm.submission.toelatingsproef.attachment_max
                            )?_c('div',[_vm._v(" "+_vm._s(_vm.submission.toelatingsproef.attachment_max)+" ")]):_vm._e(),(
                                _vm.submission.toelatingsproef.attachment_min !=
                                _vm.submission.toelatingsproef.attachment_max
                            )?_c('div',[_vm._v(" "+_vm._s(_vm.submission.toelatingsproef.attachment_min)+"-"+_vm._s(_vm.submission.toelatingsproef.attachment_max)+" ")]):_vm._e()]),_c('dt',[_vm._v(_vm._s(_vm._f("translate")("Maximum totaalgrootte")))]),_c('dd',[_vm._v(" "+_vm._s(_vm.prettyBytes( _vm.submission.toelatingsproef .attachment_total_filesize_mb * 1000 * 1000 ))+" ")]),_c('dt',[_vm._v(" "+_vm._s(_vm._f("translate")("Toegelaten bestandsextensies"))+" ")]),_c('dd',[_vm._v("pdf, wav, mp3, mp4")]),_c('dt',[_vm._v(_vm._s(_vm._f("translate")("Bestanden")))]),_c('dd'),_c('dt'),_c('dd',[_c('table',{staticClass:"table table-striped"},[_c('thead',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm._f("translate")("Bestand")))]),_c('th',{staticStyle:{"text-align":"right"}},[_c('button',{staticClass:"btn btn-primary",attrs:{"id":"uploadButton","disabled":!_vm.uploadButtonReady ||
                                                _vm.submission.attachments
                                                    .length ==
                                                    _vm.submission
                                                        .toelatingsproef
                                                        .attachment_max ||
                                                !_vm.uploadInstance ||
                                                _vm.uploadInstance.isUploading()}},[_vm._v(" "+_vm._s(_vm._f("translate")("Nieuwe upload"))+" ")])])]),(
                                        _vm.uploadInstance &&
                                        _vm.uploadInstance.isUploading()
                                    )?_c('tr',[_c('th',{attrs:{"colspan":"10"}},[_c('progress-bar',{attrs:{"size":"huge","bar-color":"#2e288c","val":_vm.uploadInstance.progress() *
                                                100}})],1)]):_vm._e()]),_c('tbody',[(
                                        _vm.submission.attachments.length == 0
                                    )?_c('tr',[_c('td',{attrs:{"colspan":"10"}},[_vm._v(" "+_vm._s(_vm._f("translate")("Geen"))+" ")])]):_vm._e(),_vm._l((_vm.submission.attachments),function(attachment){return _c('tr',{key:attachment.id},[_c('td',[_vm._v(" "+_vm._s(attachment.human_filename)+" - "+_vm._s(_vm.prettyBytes(attachment.filesize))+" ")]),_c('td',{staticStyle:{"text-align":"right"}},[_c('button',{staticClass:"btn btn-danger",on:{"click":function($event){return _vm.deleteAttachment(attachment)}}},[_c('i',{staticClass:"fas fa-trash-alt"})])])])})],2)])]),_c('dt',[_vm._v(" "+_vm._s(_vm._f("translate")("Versturen"))+" ")]),_c('dd',[(
                                _vm.submission.attachments.length <
                                _vm.submission.toelatingsproef.attachment_min
                            )?_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm._f("translate")("Onvoldoende bestanden"))+" ")]):_vm._e(),_c('div',[_c('p',[_vm._v(" "+_vm._s(_vm._f("translate")("Bevestig eerst dat je geen robot bent:"))+" ")]),_c('vue-recaptcha',{ref:"recaptcha",staticClass:"mb-2",attrs:{"sitekey":_vm.$store.state.settings
                                        .google_captcha_public_key},on:{"verify":_vm.onCaptchaVerify}}),_c('button',{staticClass:"btn btn-primary btn-lg",attrs:{"disabled":_vm.submission.attachments.length <
                                        _vm.submission.toelatingsproef
                                            .attachment_min ||
                                    !_vm.recaptchaResponse},on:{"click":function($event){return _vm.submitConfirm()}}},[_c('i',{staticClass:"fas fa-paper-plane"}),_vm._v(" "+_vm._s(_vm._f("translate")("Versturen"))+" ")])],1)])])]):_vm._e(),(_vm.submissionUploaded)?_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"alert alert-primary d-flex align-items-center",attrs:{"role":"alert"}},[_c('i',{staticClass:"fas fa-check-circle fa-2x me-2"}),_c('div',[_c('h4',{staticClass:"alert-heading"},[_vm._v(" "+_vm._s(_vm._f("translate")("Gelukt"))+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm._f("translate")("We hebben je bestanden goed ontvangen. Je krijgt binnen enkele minuten eveneens een bevestigingsmail."))+" ")])])])]):_vm._e()])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }