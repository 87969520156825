export default {
    isValidEmail: function(mail) {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
            return true;
        }
        return false;
    },
    handleError(error) {
        if (error.response && error.response.data) {
            if (error.response.data.message) {
                error = error.response.data.message;
            } else if (error.response.data.exception) {
                error = error.response.data.exception;
            } else if (error.response.data.error) {
                error = error.response.data.error;
            } else {
                error = error.response.data;
            }
            console.error("handleError", error);
        } else {
            console.error("handleError (no data)", error);
        }

        return error;
    }
};
